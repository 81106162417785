<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Course List</h4>
              <p class="card-title-desc">
                현재까지 등록 된 코스 리스트를 보여줍니다.
              </p>
              <div class="mb-3">
                <router-link to="/course/add">
                  <button class="btn btn-primary btn-sm">등록</button>
                </router-link>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <colgroup>
                    <col width="100" />
                    <col width="200" />
                    <col width="200" />
                    <col width="*" />
                    <col width="100" />
                    <col width="80" />
                    <col width="80" />
                    <col width="80" />
                    <col width="150" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>코스명</th>
                      <th>그룹명</th>
                      <th>한줄설명</th>
                      <th>가격</th>
                      <th>오픈여부</th>
                      <th>삭제여부</th>
                      <th>정렬</th>
                      <th>관리</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in courseList" :key="item.idx">
                      <td scope="row">{{ pageData.pageStartIndex - i }}</td>
                      <td>
                        <router-link :to="`/section/${item.idx}`">{{ item.name }}</router-link>
                      </td>
                      <td>{{ item.groupName }}</td>
                      <td>{{ item.shortIntroduce }}</td>
                      <td>{{ item.price }}</td>
                      <td>{{ item.openYn }}</td>
                      <td>{{ item.deleteYn }}</td>
                      <td>{{ item.orderNum }}</td>
                      <td>
                        <b-button
                          variant="danger"
                          size="sm"
                          class="me-1"
                          @click="deleteCourse(item.idx)"
                          >삭제</b-button
                        >
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="$router.push(`/course/modify/${item.idx}`)"
                          >수정</b-button
                        >
                      </td>
                    </tr>
                    <tr v-if="courseList.length === 0">
                      <td colspan="7" class="tac">데이터가 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end table-response -->
              <b-pagination
                v-model="pageData.currentPage"
                :total-rows="pageData.totalCnt"
                :per-page="pageData.perPage"
                align="center"
                aria-controls="my-table"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Course',
    meta: [{name: 'description', content: appConfig.description}]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: 'Course Lists',
      items: [
        {
          text: 'Course',
          href: '/'
        },
        {
          text: 'List',
          href: '/'
        },
        {
          text: 'Course Lists',
          active: true
        }
      ],
      courseList: [],
      pageData: {
        currentPage: 1, // 현재 페이지
        perPage: 10, // 페이지 초기 갯수
        totalCnt: 0, // 전체 갯수
        pageStartIndex: 0 // 페이지 시작 번호
      }
    };
  },
  mounted() {
    this.initCourseList();
  },
  methods: {
    async initCourseList() {
      await this.$store
        .dispatch('course/getCourseList', {
          page: this.pageData.currentPage,
          perPage: this.pageData.perPage
        })
        .then(res => {
          this.courseList = res.data.list;
          this.pageData.totalCnt = res.data.totalCnt;
          this.pageData.pageStartIndex =
            res.data.totalCnt - (this.pageData.currentPage - 1) * this.pageData.perPage;
        });
    },
    deleteCourse(idx) {
      Swal.fire({
        title: '코스를 삭제하시겠습니까?',
        text: '삭제한 코스는 복구할 수 없습니다.',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'delete'
      }).then(async result => {
        if (result.value) {
          await this.$store
            .dispatch('course/deleteCourse', {
              id: idx
            })
            .then(res => {
              if (res.data.result) {
                Swal.fire('삭제되었습니다!', '등록된 코스가 정상적으로 삭제되었습니다.', 'success');

                this.initCourseList();
              }
            });
        }
      });
    }
  }
};
</script>
<style scoped>
table th,
table td {
  text-align: center;
}
</style>
